require('./bootstrap');

$(document).ready(function(){
    const $navbar = $('.main-nav');

    $(".hamburger").click(function(){
        $(this).toggleClass("is-active");
        $('.nav-links').toggleClass("show");
        $('.bg-overlay-nav').toggleClass("show");
        $('body').toggleClass("nav-open");
    });
    $(".bg-overlay-nav").click(function(){
        $(this).removeClass("show");
        $('.nav-links').removeClass("show");
        $('.hamburger').removeClass("is-active");
        $('body').removeClass("nav-open");
    });

    $(".sliding-link").click(function(e) {
        e.preventDefault();
        var aid = $(this).attr("href");
        $('html,body').animate({scrollTop: ($(aid).offset().top - ($navbar.outerHeight()))},'slow');

        $('.hamburger').removeClass("is-active");
        $('.nav-links').removeClass("show");
        $('body').removeClass("menu-open");
        $('#main-nav').removeClass("menu-open");
        $('#bottom-nav').removeClass("menu-open");
    });
});
